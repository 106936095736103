.hidden {
  display: none;
}

.ant-layout-header .ant-statistic-content,
.ant-layout-header .ant-statistic-content-suffix,
.ant-layout-header .ant-statistic-title,
.ant-layout-header .ant-statistic-content-value-decimal {
  font-size: 14px;
  color: white;
}

/* Removes animation from sidebar for now */

.ant-layout-sider {
  transition: none !important;
}
.ant-layout-sider * {
  transition: none !important;
}

/* Fixes flickering of scrollbars in Chrome */
/* 
body {
  overflow: auto !important;
} */

/* Fix scrollbar flickering */

.ant-layout.ant-layout-has-sider {
  overflow: auto;
}

body {
  overflow: scroll;
}
